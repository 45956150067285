import loadable from "@loadable/component"
import React from "react"

const LeadershipFlipCard = loadable(() => import("./LeadershipFlipCard"))
const JobListings = loadable(() => import("./JobListings/JobListings"))
const JobsOffsetGrid = loadable(() => import("./JobListings/JobsOffsetGrid"))
const PlatformGlobe = loadable(() => import("./PlatformGlobe"))
const Whatsapp = loadable(() => import("./Whatsapp"))
const Pricing = loadable(() => import("./Pricing"))
const Digital = loadable(() => import("./Digital"))
const ChatFlowDeck = loadable(() => import("./ChatFlowDeck"))
const ChatCommerce = loadable(() => import("./ChatCommerce"))
const SMSOptions = loadable(() => import("./SMSOptions"))
const Quiz = loadable(() => import("./Quiz"))
const ChatCommerceWizard = loadable(() => import("./ChatCommerceWizard"))
const ChatBankingWizard = loadable(() => import("./ChatBankingWizard"))
const ProductUiImg = loadable(() => import("./ProductUiImg"))
const HomePageSwitchback = loadable(() => import("./HomePageSwitchback"))
const DynamicImageGrid = loadable(() => import("./DynamicImageGrid"))
const Promotions = loadable(() => import("./Promotions/PromotionsListing"))

const SingleInstance = ({ component, grid }) => {
  const type = component?.component
  switch (type) {
    case "Job Listings":
      return <JobListings component={component} />
    case "Jobs Offset Grid":
      return <JobsOffsetGrid component={component} />
    case "SMS Platform Globe":
      return <PlatformGlobe component={component} grid={grid} />
    case "WhatsApp Business Solution":
      return <Whatsapp component={component} grid={grid} />
    case "Pricing":
      return <Pricing component={component} grid={grid} />
    case "Digital connection. Digital transformation.":
      return <Digital component={component} grid={grid} />
    case "Chat Flow Card Deck":
      return <ChatFlowDeck component={component} grid={grid} />
    case "Chat Commerce":
      return <ChatCommerce component={component} grid={grid} />
    case "SMS API Options":
      return <SMSOptions component={component} grid={grid} />
    case "Quiz":
      return <Quiz component={component} grid={grid} />
    case "Chat Commerce Wizard":
      return <ChatCommerceWizard component={component} grid={grid} />
    case "Chat Banking Wizard":
      return <ChatBankingWizard component={component} grid={grid} />
    case "Product UI Image":
      return <ProductUiImg />
    case "Home Page Video Switchback":
      return <HomePageSwitchback />
    case "Dynamic Image Grid":
      return <DynamicImageGrid component={component} />
    case "Leadership Flipcard":
      return <LeadershipFlipCard component={component} />
    case "Promotions Cards":
      return <Promotions component={component} />
    default:
      return null
  }
}
export default SingleInstance
